<template>
  <div class="container-fluid">
    <div class="document-menu">
      <nav class="navbar navbar-expand-lg navbar-light bg-white">
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                File
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" href="#">Open</a>
                <a class="dropdown-item" href="#">Save</a>
                <a class="dropdown-item" href="#">Save As...</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#" @click="printPreview"
                  >Print</a
                >
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#">Exit</a>
              </div>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Edit
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" href="#">Undo</a>
                <a class="dropdown-item" href="#">Redo</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#">Copy</a>
                <a class="dropdown-item" href="#">Paste</a>
              </div>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                View
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a
                  class="dropdown-item"
                  href="#"
                  @click="editorVars.showGrid = !editorVars.showGrid"
                  >Toggle Grid</a
                >
              </div>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Insert
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a
                  class="dropdown-item"
                  href="#"
                  @click="insertItem('clinic-logo')"
                  >Clinic Logo</a
                >
                <a class="dropdown-item" href="#" @click="insertItem('text')"
                  >Text</a
                >
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#" data-toggle="modal" data-target="#exampleModalCenter" @click="insertTable('Supplements')"
                  >Supplements Table</a
                >
                <a class="dropdown-item" href="#">Tests Table</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#">Page Break</a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <!-- <div class="row">
      <div class="col-12">
        <div class="p-4 w-100" id="toolbar">
          <select class="ql-size">
            <option value="small"></option>
            <option selected></option>
            <option value="large"></option>
            <option value="huge"></option>
          </select>
          <button class="ql-bold"></button>
          <button class="ql-script" value="sub"></button>
          <button class="ql-script" value="super"></button>
        </div>
      </div>
    </div> -->
    <div class="row">
      <div class="col-12">
        <div class="editor-container">
          <custom-toolbar />
        </div>
        <div class="adveditor">
          <div v-for="item in template.items" :class="itemClasses(item)">
            <div @click="handleItemClick(item)">
              <vue-editor
                v-if="item.type == 'text'"
                class="subeditor"
                v-model="item.data"
                :editor-toolbar="customToolbar"
                :editorOptions="editorSettings"
              >
              </vue-editor>
              <clinic-logo
                :data="item.data"
                v-if="item.type == 'clinic-logo'"
              />
              <item-table :item="item" v-if="item.type == 'Supplements'" />
            </div>
            <div class="mini-toolbar" v-if="itemSelected(item)">
              <div class="btn-group align-center">
                <button
                  class="btn btn-sm"
                  @click="openFeature('Auto Suggestions', 'AutoSuggestions')"
                  data-toggle="modal"
                  data-target="#exampleModalCenter"
                  v-if="itemGetSelected().type === 'text'"
                >
                  <i class="fa fa-text"></i>
                </button>
                <button
                  class="btn btn-sm"
                  @click="openFeature(itemGetSelected().type, itemGetSelected().type)"
                  data-toggle="modal"
                  data-target="#exampleModalCenter"
                  v-if="itemGetSelected().type === 'Supplements'"
                >
                  <i class="fa fa-pencil"></i>
                </button>
                <button class="btn btn-sm" @click="itemMove(item, -1)">
                  <i class="fa fa-arrow-up"></i>
                </button>
                <button class="btn btn-sm" @click="itemMove(item, 1)">
                  <i class="fa fa-arrow-down"></i>
                </button>
                <button class="btn btn-sm bg-red" @click="itemDelete(item)">
                  <i class="fa fa-trash"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="container footer sticky-bottom">
        <div class="row">
          <div class="col">
            <p class="text-muted"><b>Status:</b> Editting File ({{ editorVars.editors }})</p>
          </div>
          <div class="col">
            <p class="text-muted"><b>Document:</b> New Template</p>
          </div>
          <div class="col">
            <p class="text-muted"><b>Last Autosave:</b> {{ editorVars.autosave ? editorVars.autosave.updated_at : 'not saved'}}</p>
          </div>
        </div>
    </footer>

    <div
      class="modal fade"
      id="exampleModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              {{ feature.name }}
            </h5>
            <button
              type="button"
              class="close btn-sm"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
              <auto-suggestions :item="itemGetSelected()" v-if="feature.component == 'AutoSuggestions'" />
              <supplements :item="itemGetSelected()" v-if="feature.component == 'Supplements'" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Quill from "quill";
import ImageResize from "quill-image-resize-module--fix-imports-error";
import quillTable from "quill-table";

import "quill/dist/quill.snow.css";
import "quill-table/src/css/quill.table.css";
import CustomToolbar from "./partials/custom-toolbar.vue";
import AutoSuggestions from "./features/AutoSuggestions.vue";
import ClinicLogo from "./features/items/ClinicLogo.vue";
import Supplements from "./features/Supplements.vue";
import ItemTable from "./features/items/Table.vue"

Quill.register("modules/imageResize", ImageResize);
Quill.register(quillTable.TableCell);
Quill.register(quillTable.TableRow);
Quill.register(quillTable.Table);
Quill.register(quillTable.Contain);
Quill.register("modules/table", quillTable.TableModule);

var Font = Quill.import("formats/font");
Font.whitelist = ["serif", "monospace", "mirza", "roboto"];
Quill.register(Font, true);

const maxRows = 10;
const maxCols = 5;
const tableOptions = [];
for (let r = 1; r <= maxRows; r++) {
  for (let c = 1; c <= maxCols; c++) {
    tableOptions.push("newtable_" + r + "_" + c);
  }
}

export default {
  components: {
    CustomToolbar,
    AutoSuggestions,
    ClinicLogo,
    Supplements,
    ItemTable
  },
  props: {
    inputView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      startTemplate: {
        items: [
          {
            uuid: "item_1",
            type: "text",
            data: "",
          },
        ],
      },
      template: null,
      editorVars: {
        selectedItem: null,
        showGrid: false,
        autosave: null,
        editors: 1,
      },
      feature: {
        name: "",
        component: "",
        item: null
      },
      client: null,
      customToolbar: [
        [{ font: [] }],
        [{ color: [] }, { background: [] }],
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["link", "image"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [
          { table: tableOptions },
          { table: "append-row" },
          { table: "append-col" },
        ],
        ["clean"],
      ],
      editorSettings: {
        modules: {
          imageResize: {
            displaySize: true,
          },
          table: true,
          toolbar: "#customToolbar",
        },
      },
      toolSettings: {
        modules: {
          imageResize: {
            displaySize: true,
          },
          table: true,
        },
      },
    };
  },
  created() {
    this.template = JSON.parse(JSON.stringify(this.startTemplate));
    this.editorVars.selectedItem = this.template.items[0].uuid;
  },
  methods: {
    openFeature(name, component) {
      this.feature.name = name;
      this.feature.component = component;
    },
    itemGetSelected() {
      if (!this.editorVars.selectedItem) return null;
      return this.template.items.find((i) => {
        return i.uuid === this.editorVars.selectedItem;
      });
    },
    itemSelected(item) {
      return this.editorVars.selectedItem == item.uuid;
    },
    itemClasses(item) {
      let classes = this.itemSelected(item)
        ? "ql-custom-selected-item"
        : "ql-custom-item";
      classes += this.editorVars.showGrid ? " ql-grid" : "";
      return classes;
    },
    itemMove(item, where) {
      let index = this.template.items
        .map(function (e) {
          return e.uuid;
        })
        .indexOf(item.uuid);
      let new_index = index + where;
      if(new_index >= 0 && new_index < this.template.items.length) {
        this.array_move(this.template.items, index, new_index);
        this.editorVars.selectedItem = item.uuid;
      }
    },
    itemDelete(item) {
      let index = this.template.items
        .map(function (e) {
          return e.uuid;
        })
        .indexOf(item.uuid);
      this.template.items.splice(index, 1);
    },
    insertItem(type) {
      let item = {
        uuid: "item_" + this.uuidv4(),
        type: type,
        data: [],
      };
      this.template.items.push(item);
      this.editorVars.selectedItem = item.uuid;
    },
    insertTable(type) {
      this.insertItem(type);
      this.openFeature(type, type);
    },
    handleItemClick(item) {
      this.editorVars.selectedItem = item.uuid;
    },
    printPreview() {
      this.$nextTick(() => {
        const element = window.document.getElementById("adveditor");

        let stylesHtml = "";
        for (const node of [
          ...document.querySelectorAll('link[rel="stylesheet"], style'),
        ]) {
          stylesHtml += node.outerHTML;
        }

        const win = window.open(
          "",
          "_blank",
          "toolbar=0,scrollbars=0,status=0",
          true
        );
        win.document.write(`
        <html>
          <head>
            <meta name="viewport" content="width=device-width, initial-scale=1">
            ${stylesHtml}
            <title>Document Preview</title>
          </head>
          <body>
            ${element.innerHTML}
          </body>
        </html>
      `);
        setTimeout(() => {
          win.document.close();
          win.focus();
          win.print();
          win.close();
          cb();
        }, 1000);
      });
    },
  },
};
</script>

<style>
.mini-toolbar {
  z-index: 1000;
  margin-top: 3px;
  left: 50%;
  justify-content: center;
  background-color: #f1f1f1;
  position: absolute;
  box-shadow: rgb(150 150 150) 0px 0px 0px 1px;
}

.fa:hover {
  color: blue !important;
}

.text-item {
  min-height: 1.1cm !important;
  padding: 0 0 0 0 !important;
  line-height: 1cm;
  padding: 0.1cm;
  width: 100%;
  font-family: Helvetica,Arial,sans-serif;
}

.ql-editor {
  font-size: 0.5cm !important;
}

.ql-custom-item {
  margin-top: 0.1cm;
}

.ql-grid {
  border: 1px solid #ccc;
}

.ql-custom-selected-item {
  margin-top: 0.1cm;
  box-shadow: rgb(0 101 255) 0px 0px 0px 1px;
}

.editor-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.document-menu {
  width: 100%;
  height: 50px;
  position: sticky;
  top: 0;
  z-index: 2;
}

.navbar {
  padding: 0px 10px !important;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 40px;
  background-color: #f5f5f5;
}

/* Custom Fonts */

#customToolbar .ql-font span[data-label="aref ruqaa"]::before {
  font-family: "Aref Ruqaa";
}
#customToolbar .ql-font span[data-label="mirza"]::before {
  font-family: "Mirza";
}
#customToolbar .ql-font span[data-label="roboto"]::before {
  font-family: "Roboto";
}

.ql-font-mirza {
  font-family: "Mirza";
}
.ql-font-roboto {
  font-family: "Roboto";
}
</style>
