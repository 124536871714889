<template>
  <v-select :options="filterActiveClients"
            label="name"
            :clearable="false"
            v-model="selected">
    <template v-slot:option="option">
      <div class="d-flex">
        <span class="truncate">{{ option.name }}</span>
      </div>
    </template>
    <template v-if='loading' #selected-option="{ name }">
      <i class="fad fa-spin fa-spinner fa-2x me-1" />
      Loading...
    </template>
    <template v-else #selected-option="{ name }">
      <i class="fad fa-user fa-2x text-primary me-2" />
      <span class="truncate">{{ name }}</span>
    </template>
    <template #no-options="{ search, searching, loading }">
      No clients available.
    </template>
  </v-select>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
export default {
  data() {
    return {
      loading: true,
      clients: [],
      selected: this.value,
    };
  },
  watch: {
    value() {
      this.selected = this.value;
    },
    selected() {
      if (this.selected && !this.selected.disabled) {
        this.$emit("input", this.selected);
      }
    },
  },
  methods: {
    isSelected(el) {
      if (this.selectedElements && this.selectedElements.length) {
        return this.selectedElements.lastIndexOf(el.id) > -1;
      }
      return this.selected && this.selected.id === el.id
    },
    fetchClients() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/clients/all").then(({ data }) => {
        this.clients = data;
        this.loading=false;
      });
    },
  },
  mounted() {
    this.fetchClients();
  },
  computed: {
    filterActiveClients() {
      return this.clients.filter(client => {
        return client.active;
      });
    },
  },
  components: {
    vSelect
  }
};
</script>
<style>
.v-select {
  background: #ffffff;
}
</style>
