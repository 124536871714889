<template>
  <div class="mb-3 border p-2" style="position: relative">
    <table class="table">
      <thead>
        <tr>
          <th v-for="(h, hkey) in item.data.headers" :key="hkey">
            {{ h }}
          </th>
          <!-- editor col -->
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(r, rkey) in item.data.rows" :key="rkey">
          <template v-for="(dc, dckey) in r">
            <td v-if="editItem !== rkey">{{ dc }}</td>
            <td v-else><input class="form-control" typ="text" v-model="item.data.rows[rkey][dckey]" /></td>
          </template>
          <!-- editor col -->
          <td>
            <button
              class="btn btn-xs btn-light"
              @click="editRow(rkey)"
              v-if="editItem !== rkey"
            >
              Edit
            </button>
            <button
              class="btn btn-xs btn-light"
              @click="editRow(rkey)"
              v-else
            >
              Save
            </button>
            <button
              class="btn btn-xs btn-light"
              @click="deleteTableRow(rkey)"
            >
              <i class="fa fa-trash"></i>
            </button>
          </td>
        </tr>
      </tbody>
      </table>
      <SupplementsSuggestions />
  </div>
</template>

<script>
import SupplementsSuggestions from "./suggestions/Supplements";

export default {
  props: ["item"],
  data() {
    return {
      supplements: [],
      editItem: null,
      toggleFeature: false,
      initialiseItem: {
        data: {
          headers: ["SKU", "Title", "Dosage", "Comments", "Price"],
          rows: [
            ["1234", "Your title here", "Take 6 daily", "Some commentary in here", 12.52],
            ["56789", "Your title here", "Take 2 daily", "Some commentary in here", 15.54]
          ],
        }
      }
    };
  },
  methods: {
    editRow(item) {
      if (this.editItem == item) {
        this.editItem = null;
        this.updateTemplate();
      } else {
        this.editItem = item;
      }
    },
    deleteTableRow(item) {
      console.log(item);
      this.item.data.rows.splice(item, 1);
    },
    getSupplements() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/supplements")
        .then(({ data }) => {
          this.supplements = data;
        });
    },
    isSelected(itemId) {
      if (
        this.$stash.currentSelectedItem &&
        this.$stash.currentSelectedItem.id == itemId
      ) {
        return true;
      }

      return false;
    },
    isTheItemSelected(itemId) {
      var tclass = this.isSelected(itemId) ? "border-danger" : "border-light";
      return tclass;
    },
    selectThisItem() {
      this.$stash.unsavedChanges = true;
      this.$stash.currentSelectedItem = null;
      this.$stash.currentSelectedItem = this.item;
    },
    addSupplement(data) {
      console.log(data);
      this.item.data.rows.push([data.sku, data.title, data.dosage, data.comments, data.price]);
    },
    initialiseTable() {
      if(this.item.data == null || this.item.data.length < 1) {
        this.item.data = JSON.parse(JSON.stringify(this.initialiseItem.data));
      }
    }
  },
  mounted: function () {
    this.initialiseTable();
    this.getSupplements();
  },
  watch: {
    item: {
      handler(new1, old1){
        if(new1.uuid !== old1.uuid) {
          this.initialiseTable();
        }
      },
      deep: true
    }
  },
  components: {
    SupplementsSuggestions,
  },
  created() {
    this.$EventBus.$on("addSupplement", this.addSupplement);
  },
  beforeDestroy(){
    this.$EventBus.$off("addSupplement", this.addSupplement);
  }
};
</script>

<style scoped>
.floating-remove-table-row {
  position: absolute;
  right: 0.25rem;
  top: 0rem;
}
.rowCol-truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 1px;
}
.fixed-table {
  table-layout: fixed;
  width: 100%;
}

.fixedScrollTable {
  max-height: 200px;
  display: inline-block;
  overflow: auto;
  scrollbar-width: thin;
  cursor: pointer;
}

.longTextWrap {
  word-break: break-word;
}
</style>