<template>
  <div>
    <div class="my-2">
      <button
        class="btn btn-outline-primary"
        @click="Settings.type = 0"
        v-if="Settings.type !== 0"
      >
        Go Back
      </button>
    </div>
    <div class="row">
      <div class="col">
        <div class="mb-3 text-center">
          <div class="row">
              <div class="col-4 mx-auto" v-if="!Settings.type || Settings.type === 1">
                <div class="card mb-4 box-shadow" v-if="!Settings.type">
                  <div class="card-header">
                    <h4 class="my-0 font-weight-normal">Health Report</h4>
                  </div>
                  <div class="card-body f-height">
                    <ul class="list-unstyled mt-3 mb-4">
                      <li><b>Features</b></li>
                      <li>Advanced Formatting</li>
                      <li>Interactive Media</li>
                      <li>Phraseology Library</li>
                      <li>Products Library</li>
                      <li>Client Data</li>
                      <li>-</li>
                      <li><b>Outputs</b></li>
                      <li>Interactive Report</li>
                      <li>PDF Document</li>
                      <li>A4 Printout</li>
                    </ul>
                  </div>
                  <div style="card-footer">
                    <button
                      type="button"
                      class="btn btn-lg btn-block btn-primary mb-3"
                      @click="Settings.type = 1"
                    >
                      Select
                    </button>
                  </div>
                </div>
                <div class="card mb-4 box-shadow" v-else>
                  <div class="card-header">
                    <h4 class="my-0 font-weight-normal">Health Report</h4>
                  </div>
                  <div class="card-body f-height">
                    <p>Start a client report or create a template?</p>
                    <select class="form-select form-select-lg mb-3" v-model="Settings.template" id="docType">
                      <option value="1" selected>Document Template</option>
                      <option value="0">Client Health Report</option>
                    </select>
                  </div>
                  <div style="card-footer">
                    <button
                      type="button"
                      class="btn btn-lg btn-block btn-primary mb-3"
                      @click="Settings.type = 1"
                      v-if="!Settings.type"
                    >
                      Select
                    </button>
                    <button
                      type="button"
                      class="btn btn-lg btn-block btn-primary mb-3"
                      @click="updated()"
                      v-else
                    >
                      Begin
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-4 mx-auto" v-if="!Settings.type || Settings.type === 2">
                <div class="card mb-4 box-shadow" v-if="!Settings.type">
                  <div class="card-header">
                    <h4 class="my-0 font-weight-normal">Letter</h4>
                  </div>
                  <div class="card-body f-height">
                    <ul class="list-unstyled mt-3 mb-4">
                      <li><b>Features</b></li>
                      <li>Print Formatting</li>
                      <li>Phraseology Library</li>
                      <li>-</li>
                      <li><b>Outputs</b></li>
                      <li>PDF Document</li>
                      <li>A4 Printout</li>
                      <li>A5 Letter Printout</li>
                    </ul>
                  </div>
                  <div style="card-footer">
                    <button
                      type="button"
                      class="btn btn-lg btn-block btn-primary mb-3"
                      @click="Settings.type = 2"
                      v-if="!Settings.type"
                    >
                      Select
                    </button>
                    <button
                      type="button"
                      class="btn btn-lg btn-block btn-primary mb-3"
                      @click="updated()"
                      v-else
                    >
                      Begin
                    </button>
                  </div>
                </div>
                <div class="card mb-4 box-shadow" v-else>
                  <div class="card-header">
                    <h4 class="my-0 font-weight-normal">Letter</h4>
                  </div>
                  <div class="card-body f-height">
                    <p>Start a client report or create a template?</p>
                    <select class="form-select form-select-lg mb-3" v-model="Settings.template" id="docType">
                      <option value="1" selected>Document Template</option>
                      <option value="0">Client Health Report</option>
                    </select>
                    <template v-if="Settings.template == 0">
                      <hr/>
                      <select-client v-model="Settings.client"
                      />
                    </template>
                  </div>
                  <div style="card-footer">
                    <button
                      type="button"
                      class="btn btn-lg btn-block btn-primary mb-3"
                      @click="Settings.type = 2"
                      v-if="!Settings.type"
                    >
                      Select
                    </button>
                    <button
                      type="button"
                      class="btn btn-lg btn-block btn-primary mb-3"
                      @click="updated()"
                      v-else
                    >
                      Begin
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-4 mx-auto" v-if="!Settings.type || Settings.type === 3">
                <div class="card mb-4 box-shadow">
                  <div class="card-header">
                    <h4 class="my-0 font-weight-normal">Custom Invoice</h4>
                  </div>
                  <div class="card-body f-height">
                    <ul class="list-unstyled mt-3 mb-4">
                      <li><b>Features</b></li>
                      <li>Advanced Formatting</li>
                      <li>Phraseology Library</li>
                      <li>Supplements Library</li>
                      <li>Tests Library</li>
                      <li>Client Data</li>
                      <li>Order Data</li>
                      <li>-</li>
                      <li><b>Outputs</b></li>
                      <li>PDF Document</li>
                      <li>A4 Printout</li>
                    </ul>
                  </div>
                  <div style="card-footer">
                    <button
                      type="button"
                      class="btn btn-lg btn-block btn-primary mb-3"
                      disabled
                    >
                      Coming Soon
                    </button>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import SelectClient from "../../../components/clients/SelectClient.vue"
export default {
  components: {
    SelectClient
  },
  props: {
    type: Number,
  },
  data() {
    return {
      Settings: {
        type: null,
        template: 1,
      },
    };
  },
  methods: {
    updated() {
      this.$emit("updated", this.Settings);
    },
  },
};
</script>
<style scoped>
.f-height {
  min-height: 350px;
}
</style>