<template>
  <div>
    <table class="table">
      <thead>
        <tr v-if="item.data.headers.length > 0">
          <th v-for="(h, hkey) in item.data.headers" :key="hkey">
            {{ h }}
          </th>
        </tr>
      </thead>
      <tbody v-if="item.data.rows.length > 0">
        <tr v-for="(r, rkey) in item.data.rows" :key="rkey">
          <td v-for="(dc, dckey) in r" :key="dckey">
            {{ dc }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {
      loaded: false,
    };
  },
  created() {
    this.loaded = true;
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
  },
};
</script>

<style>
</style>