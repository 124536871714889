<template>
   <div class="container-fluid">
       <document-type @updated="update" v-if="!DocumentSettings" />
       <advanced-editor :DocumentSettings="DocumentSettings" v-else-if="DocumentSettings.type == 1" />
       <basic-editor :DocumentSettings="DocumentSettings" v-else-if="DocumentSettings.type == 2" />
   </div>
</template>
<script>
import DocumentType from './index-partials/DocumentType.vue'
import BasicEditor from './editor/BasicEditor.vue'
import AdvancedEditor from './editor/AdvancedEditor.vue'

export default {
  data() {
    return {
      DocumentSettings: null,
    };
  },
  methods: {
      update(Settings) {
          this.DocumentSettings = Settings;
      }
  },
  components: {
      DocumentType,
      BasicEditor,
      AdvancedEditor
  }
};
</script>
