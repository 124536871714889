<template>
  <div class="container-fluid">
    <div class="document-menu">
      <nav class="navbar navbar-expand-lg navbar-light bg-white">
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                File
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" href="#">Open</a>
                <a class="dropdown-item" @click="DocumentSave">Save</a>
                <a class="dropdown-item" href="#">Save As...</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#" @click="printPreview">Print</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#">Exit</a>
              </div>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Edit
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" href="#">Undo</a>
                <a class="dropdown-item" href="#">Redo</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#">Copy</a>
                <a class="dropdown-item" href="#">Paste</a>
              </div>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Insert
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" href="#" @click="insertItem(user.clinic.name)">Clinic Name</a>
                <a class="dropdown-item" href="#" @click="insertItem(user.name)">Practitioner Name</a>
                <template v-if="!isTemplate()">
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#" @click="insertItem(client.first_name + ' ' + client.last_name)">Client Name</a>
                </template>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <div class="editor-container">
      <vue-editor
        id="editor"
        ref="editor"
        v-model="template"
        :editor-toolbar="customToolbar"
        :editorOptions="editorSettings"
        useCustomImageHandler
        @image-added="handleImageAdded"
      ></vue-editor>
    </div>
  </div>
</template>

<script>
import Quill from "quill";
import ImageResize from "quill-image-resize-module--fix-imports-error";
import quillTable from 'quill-table';
import "quill/dist/quill.snow.css";
import "quill-table/src/css/quill.table.css";

Quill.register("modules/imageResize", ImageResize);
Quill.register(quillTable.TableCell);
Quill.register(quillTable.TableRow);
Quill.register(quillTable.Table);
Quill.register(quillTable.Contain);
Quill.register('modules/table', quillTable.TableModule);

const maxRows = 10;
const maxCols = 5;
const tableOptions = [];
for (let r = 1; r <= maxRows; r++) {
    for (let c = 1; c <= maxCols; c++) {
        tableOptions.push('newtable_' + r + '_' + c);
    }
}

export default {
  props: ["DocumentSettings"],
  data() {
    return {
      template: null,
      client: null,
      customToolbar: [
        [{ font: fonts }],
        [{ color: [] }, { background: [] }],
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["link", "image"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{table: tableOptions}, {table: 'append-row'}, {table: 'append-col'}],
        ["clean"],
      ],
      editorSettings: {
        modules: {
          imageResize: {
            displaySize: true,
          },
          table: true
        },
      },
      happy: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  methods: {
    DocumentSave() {
      console.log("template: ", this.template);
    },
    handleImageAdded() {},
    insertItem(item) {
      /* let currentDelta = this.$refs.editor.quill.getContents();
      var delta = this.$refs.editor.quill.clipboard.convert(item);
      let combinedDelta = currentDelta.concat(delta);
      this.$refs.editor.quill.setContents(combinedDelta); */
      this.$refs.editor.quill.root.innerHTML += item;

    },
    printPreview() {
      this.$nextTick(() => {
        const element = window.document.getElementById("editor");

        let stylesHtml = "";
        for (const node of [
          ...document.querySelectorAll('link[rel="stylesheet"], style'),
        ]) {
          stylesHtml += node.outerHTML;
        }

        const win = window.open(
          "",
          "_blank",
          "toolbar=0,scrollbars=0,status=0",
          true
        );
        win.document.write(`
        <html>
          <head>
            <meta name="viewport" content="width=device-width, initial-scale=1">
            ${stylesHtml}
            <title>Document Preview</title>
          </head>
          <body>
            ${element.innerHTML}
          </body>
        </html>
      `);
        setTimeout(() => {
          win.document.close();
          win.focus();
          win.print();
          win.close();
          cb();
        }, 1000);
      });
    },
    isTemplate() {
      return this.DocumentSettings.template === 1;
    }
  },
  mounted() {

    if(!this.isTemplate()) {
      this.client = this.DocumentSettings.client;
    }
  }
};
</script>

<style>

.editor-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.document-menu {
  width: 100%;
  height: 50px;
  position: sticky;
  top: 0;
  z-index: 2;
}

.navbar {
  padding: 0px 10px !important;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 40px;
  background-color: #f5f5f5;
}
</style>
