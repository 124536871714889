<template>
  <div class="text-center">
    <template v-if="loaded">
    <img :src="data.clinic_logo_url" :alt="data.clinic_name" class="clinic-logo" />
    <h4 class="mb-2">{{ data.clinic_name }}</h4>
    </template>
  </div>
</template>

<script>
export default {
  props: ["data"],
  data() {
    return {
      loaded: false,
    };
  },
  created() {
    this.data.clinic_logo_url = this.user.clinic.photo_url;
    this.data.clinic_name = this.user.clinic.name;
    this.loaded = true;
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
  }
};
</script>

<style>
.clinic-logo {
  width: 150px;
  height: 150px;
  display: initial !important;
}
</style>