var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{attrs:{"options":_vm.filterActiveClients,"label":"name","clearable":false},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"truncate"},[_vm._v(_vm._s(option.name))])])]}},(_vm.loading)?{key:"selected-option",fn:function(ref){
var name = ref.name;
return [_c('i',{staticClass:"fad fa-spin fa-spinner fa-2x me-1"}),_vm._v(" Loading... ")]}}:{key:"selected-option",fn:function(ref){
var name = ref.name;
return [_c('i',{staticClass:"fad fa-user fa-2x text-primary me-2"}),_c('span',{staticClass:"truncate"},[_vm._v(_vm._s(name))])]}},{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" No clients available. ")]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }